import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import makeCarousel from "react-reveal/makeCarousel"
import Slide from "react-reveal/Slide"
import styled from "styled-components"

const Container = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  width: 30rem;
  margin: 0 auto;
  background: var(--shade);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  @media (min-width: 768px) {
    display: block;
    width: 100%;
  }
`
const Children = styled.div`
  width: 30rem;
  position: relative;
  height: 25rem;
  margin: 1.5rem auto 0;
  @media (min-width: 768px) {
    width: 50rem;
    height: 20rem;
  }
  @media (min-width: 1024px) {
    width: 70rem;
    height: 22rem;
  }
`

const CarouselUI = ({ position, total, handleClick, children }) => (
  <Container>
    <Children>{children}</Children>
  </Container>
)

const Carousel = makeCarousel(CarouselUI)

export default function HomeCarousel({ testimonials }) {
  return (
    <Carousel defaultWait={5000} maxTurns={100000}>
      {testimonials.map(testimonial => (
        <Slide top key={testimonial.id}>
          <div key={testimonial.id}>
            <GatsbyImage
              image={testimonial.image.asset.gatsbyImageData}
              alt={testimonial.index}
            />
          </div>
        </Slide>
      ))}
    </Carousel>
  )
}
