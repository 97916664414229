import React from "react"
import styled from "styled-components"
import AnswersImage from "../images/answers-img"

const StyledHomeDiv = styled.div`
  width: 100%;
  padding: 1rem;
  background: var(--shade);
  box-shadow: 0 0 0.25rem rgba(0,0,0,0.75);
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  a {
    :hover {
      text-decoration: none;
    }
  }
  @media (min-width: 768px) {
    width: 32.5%;
  }
`;

const StyledHeading = styled.h3` 
  color: var(--gold);
  text-shadow: 0.2rem 0.2rem #412456;
  font-size: 1.8rem;
  :hover {
    text-decoration: none;
  }
`;

export default function HomeAnswers() {
  return (
    <StyledHomeDiv>
      <a href="https://answers.askthetarot.com.au" rel=
      "noreferrer" target="_blank" >
        <StyledHeading className="m-3 text-center">Yes or No</StyledHeading>
          <AnswersImage />
        <StyledHeading className="m-3 text-center">Questions</StyledHeading>
      </a>
    </StyledHomeDiv>

  )
}