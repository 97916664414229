import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import TarotImage from "../images/tarot-img"

const StyledHomeDiv = styled.div`
  width: 100%;
  padding: 1rem;
  background: var(--shade);
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.25rem rgba(0,0,0,0.75);
  a {
    :hover {
      text-decoration: none;
    }
  }
  @media (min-width: 768px) {
    width: 32.5%;
  }
`

const StyledHeading = styled.h3`
  color: var(--gold);
  text-shadow: 0.2rem 0.2rem #412456;
  font-size: 1.8rem;
  :hover {
    text-decoration: none;
  }
`

export default function HomeTarot() {
  return (
    <StyledHomeDiv>
      <Link to="/tarot">
        <StyledHeading className="m-3 text-center">Get To Know</StyledHeading>
        <TarotImage />
        <StyledHeading className="m-3 text-center">The Tarot</StyledHeading>
      </Link>
    </StyledHomeDiv>
  )
}
