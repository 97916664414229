import React from "react"
import styled from "styled-components"

const StyledPanel = styled.div` 
  width: 100%;
  display: flex;
  background: var(--shade);
  border-radius: 1rem;
  margin: 2rem auto;
  box-shadow: 0 0 0.25rem rgba(0,0,0,0.75);
`;

const StyledIntro = styled.div` 
  width: 90%;
  margin: 0.5rem auto 0;
  padding: 2rem;
  color: var(--gold);
  background: transparent;
  text-align: center;
  h2, h3 {
    color: var(--gold);
    
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  p {
    font-weight: bold;
    font-size: 1.6rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
`;

function HomeIntro({ intro }) {
  return (
    <StyledIntro>
      <h2>{intro.headline}</h2>
      <p>{intro.subhead1}</p>
      <p>{intro.subhead2}</p>
      <h3>{intro.midhead}</h3>
      <p>{intro.midpara}</p>
      <h3>{intro.midhead1}</h3>
      <p>{intro.closepara}</p>
      <p>{intro.closepara1}</p>
    </StyledIntro>
  )
}

export default function HomeIntroduction({ intros }) {
  return (
    <StyledPanel>
      {intros.map((intro) => (
        <HomeIntro key={intro.id} intro={intro} />
      ))}

    </StyledPanel>
  )
}