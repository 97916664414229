import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledHomeDiv = styled.div`
  width: 100%;
  padding: 1rem;
  background: var(--shade);
  margin-top: 0;
  margin-bottom: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  a {
    :hover {
      text-decoration: none;
    }
  }
  /* @media (min-width: 768px) {
    width: 32.5%;
  } */
`

const StyledHeading = styled.h3`
  color: var(--gold);
  text-shadow: 0.2rem 0.2rem #412456;
  font-size: 1.8rem;
  :hover {
    text-decoration: none;
  }
`

export default function HomeDailyRead() {
  return (
    <StyledHomeDiv>
      <Link to="/dailyread/">
        <StyledHeading className="m-3 text-center">
          Free Daily Tarot Reading
        </StyledHeading>
      </Link>
    </StyledHomeDiv>
  )
}
