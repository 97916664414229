import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import HomeIntroduction from "../components/homeintroduction"
import HomeCarousel from "../components/homecarousel"
import HomeChakra from "../components/homechakra"
import HomeTarot from "../components/hometarot"
import HomeAnswers from "../components/homeanswers"
import HomeDailyRead from "../components/homedailyread"

const StyledContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0.5rem auto;
`

export default function IndexPage(props) {
  const intros = props.data.intros.nodes
  const testimonials = props.data.testimonials.nodes
  return (
    <>
      <SEO title="Home" />
      <StyledContainer>
        <HomeIntroduction intros={intros} />
        <HomeDailyRead />
        <HomeCarousel testimonials={testimonials} />
        <HomeChakra />
        <HomeAnswers />
        <HomeTarot />
      </StyledContainer>
    </>
  )
}

export const query = graphql`
  query introQueryAndTestimonialQuery {
    intros: allSanityIntroductions {
      nodes {
        id
        midhead
        midhead1
        midpara
        subhead1
        subhead2
        headline
        closepara
        closepara1
      }
    }
    testimonials: allSanityTestimonials {
      nodes {
        id
        index
        image {
          asset {
            gatsbyImageData(
              width: 900
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`
