import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

const TarotStyled = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
  }
`

const TarotImage = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "thirdeye.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
}
`)

  if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
    return <div>Picture not found</div>
  }

  return (
    <TarotStyled>
      <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} alt="tarot image" />
    </TarotStyled>
  );
}

export default TarotImage
